<!-- =========================================================================================
  File Name: EchartsPolarChart.vue
  Description: Create polar chart
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vx-card title="Polar Chart" class="" code-toggler>

    <span>Dark Theme</span> <vs-switch v-model="themeDark"></vs-switch>
    <div class="mt-5">
      <e-charts :options="polar" ref="bar" :theme="themeDark ? 'dark' : 'default'" auto-resize />
    </div>

    <template slot="codeContainer">
&lt;template&gt;
  &lt;e-charts :options=&quot;polar&quot; ref=&quot;bar&quot; :theme=&quot;themeDark ? 'dark' : 'default'&quot; auto-resize /&gt;
&lt;/template&gt;

&lt;script&gt;
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/polar'

import 'echarts/theme/dark'

export default {
  data() {
    let data = []

    for (let i = 0; i &lt;= 360; i++) {
      let t = i / 180 * Math.PI
      let r = Math.sin(2 * t) * Math.cos(2 * t)
      data.push([r, i])
    }
    return {
      themeDark: false,
      polar:{
          legend: {
            data: ['line']
          },
          polar: {
            center: ['50%', '54%']
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          angleAxis: {
            type: 'value',
            startAngle: 0
          },
          radiusAxis: {
            min: 0
          },
          series: [
            {
              coordinateSystem: 'polar',
              name: 'line',
              type: 'line',
              showSymbol: false,
              data: data
            }
          ],
          animationDuration: 2000
      },
    }
  },
  components: {
    ECharts
  }
}
&lt;/script&gt;
    </template>
  </vx-card>
</template>

<script>
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/polar'

import 'echarts/theme/dark'

export default {
  data () {
    const data = []

    for (let i = 0; i <= 360; i++) {
      const t = i / 180 * Math.PI
      const r = Math.sin(2 * t) * Math.cos(2 * t)
      data.push([r, i])
    }
    return {
      themeDark: false,
      polar: {
        legend: {
          data: ['line']
        },
        polar: {
          center: ['50%', '54%']
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        angleAxis: {
          type: 'value',
          startAngle: 0
        },
        radiusAxis: {
          min: 0
        },
        series: [
          {
            coordinateSystem: 'polar',
            name: 'line',
            type: 'line',
            showSymbol: false,
            data
          }
        ],
        animationDuration: 2000
      }
    }
  },
  components: {
    ECharts
  }
}
</script>
